<template>
  <!-- Main row container -->
  <b-row>
    <b-col>
      <!-- Overlay to show loading state -->
      <b-overlay
        :show="$apollo.loading || mutationLoading"
        rounded="sm"
      >
        <!-- Card container -->
        <b-card>
          <!-- Card header with back button and title -->
          <b-card-header
            class="justify-content-between p-0 pb-1 mb-1 border-bottom"
          >
            <div class="d-flex align-items-center p-0">
              <!-- Back button -->
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="outline-secondary"
                @click="$router.back()"
              >
                <feather-icon
                  class="mr-25"
                  icon="ChevronLeftIcon"
                />
              </b-button>
              <!-- Title -->
              <h3 class="mb-0 ml-2">
                Exercises
              </h3>
            </div>
          </b-card-header>
          <!-- Search input -->
          <div class="custom-search d-flex justify-content-start">
            <b-form-group
              class="form-inline"
              label="Search"
              label-size="sm"
            >
              <b-form-input
                v-model="searchTerm"
                class="d-inline-block mr-1"
                placeholder="Search Exercises"
                type="text"
              />
            </b-form-group>
          </div>
          <!-- Exercises table component -->
          <exercises-table
            :columns="columns"
            :exercises="exercises"
            :get-link="getLink"
            :operations="operations"
            :search-term="searchTerm"
          />
        </b-card>
      </b-overlay>
    </b-col>
  </b-row>
</template>

<script>
import gql from 'graphql-tag'
import ExercisesTable from '@/components/exercises/ExercisesTable.vue'
import {
  BButton, BCardHeader, BFormGroup, BFormInput, BOverlay,
} from 'bootstrap-vue'

export default {
  components: {
    BButton,
    BCardHeader,
    BOverlay,
    BFormInput,
    BFormGroup,
    ExercisesTable,
  },
  data() {
    return {
      mutationLoading: false, // Loading state for mutations
      searchTerm: '', // Search term for filtering exercises
      exercises: [], // List of exercises
      columns: [ // Table columns configuration
        {
          label: 'Title',
          field: 'title',
        },
        {
          label: 'Operation',
          field(row) {
            return row.programs_operationstable.title
          },
          filterOptions: {
            enabled: true, // Enable filter for this column
            customFilter: true, // Use custom filter component
          },
          width: '45%',
        },
        {
          label: 'Action',
          field: 'action',
          width: '13rem',
          sortable: false,
        },
      ],
      operations: [], // List of operations
      pageLength: 5, // Number of items per page
    }
  },
  methods: {
    /**
     * Get the link for a specific module and type.
     * @param {Number} moduleId - The ID of the module.
     * @param {String} type - The type of link to generate.
     * @returns {String} - The generated link.
     */
    getLink(moduleId, type) {
      if (type === 'open') {
        return this.$router.push({
          name: 'mentor-review-score',
          params: {
            pid: this.$route.params.id,
            apid: moduleId,
            aid: this.$route.params.sid,
            rid: this.$route.params.pid,
          },
        })
      }
      return this.$router.push({
        name: 'mentor-review-evaluations',
        params: {
          pid: this.$route.params.id,
          apid: moduleId,
          aid: this.$route.params.sid,
          rid: this.$route.params.pid,
        },
      })
    },
  },

  apollo: {
    operations: {
      query: gql`
      query getOperations($programId: Int!) {
        programs_operationstable(where: { program_id: { _eq: $programId }, operations_type: { _eq: "Phase" } }) {
          id
          title
          programs_operationstables(where: { operations_type: { _eq: "SubPhase" } }) {
            id
            title
            programs_operationstables {
              id
              title
            }
          }
        }
      }
    `,
      variables() {
        return {
          programId: this.$route.params.id,
        }
      },
      update(data) {
        return data.programs_operationstable || []
      },
    },
    exercises: {
      query: gql`
      query getexercises($programId: Int!) {
        programs_assignmenttable(order_by: { id: desc }, where: { programs_operationstable: { programs_basicinfo: { id: { _eq: $programId } } }, review_status: { _neq: "draft" } }) {
          id
          title
          type
          review_status

          programs_operationstable {
            title
            operations_type
          }
        }
      }
    `,
      variables() {
        return {
          programId: this.$route.params.id,
        }
      },
      update(data) {
        return data.programs_assignmenttable || []
      },
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table';
@import "~@core/scss/base/pages/app-ecommerce";
</style>
